import React, { ReactNode } from 'react'

type props = {
    title: string | any,
    content: ReactNode | any,
    close: ()=>void
}

function Modal({title, content, close}:props) {
  return <div className='w-screen h-screen fixed top-0 left-0 flex items-center justify-center bg-[#00000092] z-20 sm:p-6'>
        <div className='dark:bg-darkBg dark:text-white bg-white md:rounded-xl p-4 pt-0 sm:px-6 px-3 sm:min-w-[500px] min-h-full md:min-h-[300px] max-h-full overflow-hidden overflow-y-auto relative z-10'>
            <aside className="w-full h-14 flex items-center justify-between sticky top-0 dark:bg-darkBg dark:text-white bg-white border-b mb-5">
                <b className="font-semibold text-lg dark:text-white text-deepTheme">{title}</b>
                <i onClick={()=>close()} className="fa fa-times cursor-pointer"></i>
            </aside>
            {content}
        </div>
        <div onClick={close} className="absolute w-full h-full top-0 left-0"></div>
    </div>
}

export default Modal