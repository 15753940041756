import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../Utils/axios';
import local from '../../../Utils/Local';
import { useLocation } from 'react-router-dom';
import { upgradeServices } from '../../../Services/upgradeServices';
import useMainStore from '../../../Store/Store';


type props = {
    close: ()=>void;
}


function Upgrade({close}:props) {
    const location = useLocation();
    // const navigate = useNavigate();
    // const plans = [
    //     {
    //         name: 'Free',
    //         amount: '0',
    //         duration: 'month',
    //         features: [
    //             'User roles and permissions',
    //             'Mibile accessibility',
    //             'Integration with popular tools',
    //             'Standard customer support',
    //         ]
    //     },
    //     {
    //         name: 'Standard',
    //         amount: '29',
    //         duration: 'month',
    //         features: [
    //             'User roles and permissions',
    //             'Mibile accessibility',
    //             'Integration with popular tools',
    //             'Standard customer support',
    //             'Customization options',
    //         ]
    //     },
    //     {
    //         name: 'Pro',
    //         amount: '49',
    //         duration: 'month',
    //         features: [
    //             'User roles and permissions',
    //             'Mibile accessibility',
    //             'Integration with popular tools',
    //             'Standard customer support',
    //             'Customization options',
    //             'Basic reporting and analytics'
    //         ]
    //     },
    // ]
    const [plans, setPlans] = useState<any[]>([])
    const [selected, setSelected] = useState<any>({});
    const [renewal, setRenewal] = useState<string>('MONTHLY')
    const currentPlan = useMainStore().transaction?.pricing;
    const isExpired = useMainStore().transaction?.is_expired;
    const [loading, setLoading] = useState(false)


    useEffect(()=>{
        axiosInstance.get(`/pricing`)
        .then(res=>{
            setPlans(res.data.results)
        }).catch(()=>{})
    },[])

    useEffect(()=>{
        if(!currentPlan || isExpired){
            setSelected(()=>{
                return plans.filter(plan=>plan?.name?.includes('ree'))[0]
            })
        }else{
            setSelected(currentPlan)
        }
    },[currentPlan, plans, isExpired])

    const handleRenewalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRenewal(e.target.value);
    }

    const handleSelect = () => {
        if(selected && renewal){
            setLoading(true)
            upgradeServices.upgrade('USD', renewal, selected)
            .then((res)=>{
                setLoading(false)
                local.set('payment_session_id', res.data?.session_id)
                window.open(res.data?.payment_link, '_blank')
            }).catch(err=>{
                setLoading(false)
            })
        }
        local.set('prev', location.pathname)
    }

    return (
        <Container className=' overflow-hidden overflow-x-auto overflow-y-auto'>
            <h1 className="text-xl font-semibold">Select plan</h1>
            <p className='md:text-[0.9rem] text-[0.8rem]'>Simple and flexible per-user pricing.</p>
            <br />

            <section className="w-full mb-6 border-b pb-6">
                <h1 className="md:text-lg">{selected?.name === 'Free' ? 'Free Plan':`Upgrade to ${selected?.name}`}</h1>
                <div className="w-full grid sm:grid-cols-2 gap-4 mt-2 md:mt-4 md:text-[0.9rem] text-[0.8rem]">
                    {
                        selected?.description?.map((feature:any, idx:number)=>{
                            return <aside key={`plan-feature-${idx}`} className={`flex items-items ${`animateshow${idx<8 ? idx : 7}`}`}>
                                <i className="fa fa-check mr-2"></i>
                                <small className='flex-wrap'>{feature?.description}</small>
                            </aside>
                        })
                    }
                </div>
            </section>
            
            <section className='w-full overflow-hidden overflow-x-auto'>
                <div className="grid grid-cols-3 gap-4 mb-6 w-[500px] md:w-[800px]">
                    {
                        plans.map((plan, idx)=>{
                            return <div onClick={()=>setSelected(plan)}  key={`plan-${idx}`} className={`w-full p-3 flex flex-col border cursor-pointer rounded-xl ${selected?.name === plan?.name ? `border-theme bg-[#dae5ff88]` : `` }`}>
                                <div className="w-full flex items-center justify-between">
                                    <h1 className="">{plan?.name}</h1>
                                    <i className={`fa fa-check-circle ${selected?.name === plan?.name ? 'text-theme':'hidden'}`}></i>
                                </div>
                                <br /><br />
                                {!plan?.name?.includes('ree') ? <>
                                    <div className="flex">
                                        {currentPlan?.id !== plan?.id && <input type="radio" name="renewal" value={'MONTHLY'} checked={renewal === 'MONTHLY' && selected?.id === plan?.id} onChange={handleRenewalChange} id="" className='mr-3' />}
                                        <h1 className="text-xl font-bold"><small className='md:text-[0.9rem] text-[0.8rem]'>$</small> {plan?.usd_monthly_price} <small className="md:text-[0.9rem] text-[0.7rem] text-gray-500">/ month</small></h1>
                                    </div>
                                    <div className="flex">
                                        {currentPlan?.id !== plan?.id && <input type="radio" name="renewal" value={'YEARLY'} checked={renewal === 'YEARLY' && selected?.id === plan?.id} onChange={handleRenewalChange} id="" className='mr-3' />}
                                        <h1 className="text-xl font-bold"><small className='md:text-[0.9rem] text-[0.8rem]'>$</small> {plan?.usd_price_per_annum} <small className="md:text-[0.9rem] text-[0.7rem] text-gray-500">/ year</small></h1>
                                    </div>
                                </> : <>
                                    <h1 className="text-4xl font-bold text-theme">$0.00</h1>
                                    <div className="px-4 h-6 flex items-center rounded-xl bg-orange-500 text-white w-fit mt-2 text-sm font-semibold">
                                        Free
                                    </div>
                                </>}
                                {
                                    plan?.id === currentPlan?.id && <div className="px-4 h-6 flex items-center rounded-xl bg-emerald-500 text-white w-fit mt-2 text-sm font-semibold">
                                        Current
                                    </div>
                                }
                                
                                {/* <button onClick={()=>{
                                    
                                }} className={`mt-3 w-full rounded-lg h-10 font-semibold md:text-[0.9rem] text-[0.8rem] ${selected?.name === plan?.name ? `bg-theme text-white` : `bg-gray-300 dark:text-theme`}`}>
                                    {`${plan?.name}`.toLowerCase().includes('free') ? 'Current plan' : 'Upgrade' }
                                </button> */}
                            </div>
                        })
                    }
                </div>
            </section>
            
            {
                (!currentPlan?.name?.includes('ree') && selected?.name?.includes('ree')) && <section className=" w-[70%] border border-blue-300 p-3 rounded-xl flex mb-6">
                    <div className="min-w-5 h-5 rounded-full flex items-center justify-center border border-blue-300 text-blue-200 mr-2">
                        <i className="fa fa-info text-xs m-auto"></i>
                    </div>
                    <small className='text-gray-500 flex-wrap'>Are you sure you want to downgrade? This will remove your current plan privileges, shifting your account to the Free plan on Next Month.</small>
                </section>
            }
            
            
            <b className='text-theme sm:text-[0.9rem] text-[0.8rem]'>Compare plans and pricing options</b>
            <br />
            <br />
            <br />
            <div className="flex sm:flex-row flex-col sm:justify-end font-semibold text-[0.8rem] sm:text-sm w-full">
                <div onClick={()=>close()} className="h-10 cursor-pointer rounded-lg px-10 flex items-center justify-center sm:ml-3 sm:mb-0 mb-3 bg-gray-300">
                    Cancel
                </div>
                {
                    currentPlan ? <>
                        {
                            (!selected?.name?.includes('ree') && (currentPlan?.id !== selected?.id)) && <button onClick={()=>{handleSelect()}} className={`h-10 rounded-lg px-10 flex items-center justify-center sm:ml-3 ${loading && 'pointer-events-none'} ${(currentPlan?.id === selected?.id && !isExpired) ? 'bg-gray-400 text-black':'bg-theme text-white'}`}>
                                {loading ? 'Loading ...':'Continue'}
                            </button>
                        }
                    </> : <>
                        {
                            !selected?.name?.includes('ree') && <button onClick={()=>{handleSelect()}} className={`h-10 rounded-lg px-10 flex items-center justify-center sm:ml-3 ${loading && 'pointer-events-none'} ${(currentPlan?.id === selected?.id && !isExpired) ? 'bg-gray-400 text-black':'bg-theme text-white'}`}>
                                {loading ? 'Loading ...':'Continue'}
                            </button>
                        }
                    </>
                }
            </div>
        </Container>
    )
}

const Container = styled.div`
    width: 800px;
    @media only screen and (max-width: 1000px){
        width: 100%;
    }
`

export default Upgrade