import React, { FormEvent, MouseEvent, useEffect, useState } from 'react'
import MainWrapper from '../Components/MainWrapper'
import useMainStore from '../../../Store/Store';
import RightSideBar from '../Components/RightSideBar';
import { skeleton, skeletonPlaceholder } from '../../../Assets/Images';
import { services } from '../../../Services/mainServices';
import useMain from '../../../Hooks/useMain';
import { scanServices } from '../../../Services/ScanServices';
import Toast from '../../../Utils/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import ScanDetail from './ScanDetail';
import Empty from '../../../Common/Empty';
import axiosInstance from '../../../Utils/axios';
import { convertDate } from '../../../Utils/convertTime';

function SmartScan() {
    const ScanContent = () => {
        const params = useParams();
        const store = useMainStore();
        const {setShow} = store;
        const navigate = useNavigate();
        const setPage = store.setPage;
        const [countries, setCountries] = useState([]);
        const getCountries = () => {
            services.getCountries().then((data:any)=>{
                setCountries(data.data)
            }).catch(()=>{})
        }
        
        useEffect(()=>{
            setPage('Smart Scan');
            getCountries();
            return ()=>{
                setPage('')
            }
            // eslint-disable-next-line
        }, []);
        const [selectedCountry, setSelectedCountry] = useState<any>(null);
        const [searchCountry, setSearchCountry] = useState('')
        const [displayCountries, setDisplayCountries] = useState([])
        const [scanImage, setScanImage] = useState<any>(null)
        const {setDrop, drop} = useMain()
        const [history, setHistory] = useState<any[]>([])
        const [patients, setPatients] = useState<any[]>([])
        const [showHidden, setShowHidden] = useState(false)

        const fetchData =async () => {
            const his:any = await scanServices.getScans(store.user?.id);
            if(his){
                console.log(his.results)
                setHistory(his.results)
            }

            axiosInstance.get(`/smartscans/users/patients`)
            .then(res=>{
                console.log(res)
                setPatients(res.data?.results)
            }).catch(err=>{
                console.log(err)
            })
        }

        useEffect(()=>{
            fetchData();
            // eslint-disable-next-line
        },[])

        useEffect(()=>{
            if(searchCountry?.length>1){
                let res = countries.filter((country:any)=>{
                    let val = searchCountry.toLowerCase();
                    let name = `${country?.name?.common?.toLowerCase()}`;
                    // let code = `${country?.idd?.root}${country?.idd?.suffixes?.slice(0,1)}`;

                    return name.includes(val)
                });
                setDisplayCountries(res?.length>0?res:countries)
            }else{
                setDisplayCountries(countries)
            }
            // eslint-disable-next-line
        },[searchCountry])

        const [details, setDetails] = useState<any>({
            first_name:'',
            last_name: '',
            gender:'',
            phone:'',
            email:'',
            dob:'',
            file: '',
            prompt: ''
        })

        const [progress, setProgress] = useState(10)
        useEffect(()=>{
            if(progress+readValidation()*30<=100){
                setProgress(prev=>prev+readValidation()*30)
            }else{
                setProgress(100)
            }
            // eslint-disable-next-line
        },[details])
        const readValidation = ()=>{
            // setProgress(prev=>0)
            let res = 0
            Object.keys(details).forEach((key:any)=>{
                if(details[key]?.length>3){
                    res += 16.666
                }
            })
            return res
        }


        const handlePatientCreation = async (e: FormEvent) => {
            e.preventDefault();

            // Ensure that the details.file is correctly populated
            if (!details.file) {
                Toast('Please select an image file', 'error');
                return;
            }

            let data = {
                first_name: details.first_name,
                last_name: details.last_name,
                gender: details.gender,
                phone_number: details.phone,
                date_of_birth: details.dob,
                email: details.email,
                prompt: details.prompt
            };

            let formData = new FormData();
            formData.append('image', details.file);

            try {
                const patientRes: any = await scanServices.createPatientProfile(data);
                const status = patientRes.status ?? patientRes?.response?.status;

                if (`${status}`.includes('20')) {
                    formData.append('patient', patientRes?.data?.id);
                    if(formData.get('image')){
                        const scanRes: any = await scanServices.createScan(formData);
                        const scanStatus = scanRes.status ?? scanRes?.response?.status;
    
                        if (`${scanStatus}`.includes('20')) {
                            Toast('Scan created successfully', 'success');
                            navigate(`/smart-scan/${scanRes?.data?.id}`);
                        } else {
                            Toast('Scan creation failed', 'error');
                        }
                    }else{
                        Toast('Unsupported file type', 'warning')
                    }
                } else {
                    Toast('Patient creation failed', 'error');
                }
            } catch (error) {
                console.error('Error creating patient or scan:', error);
                Toast('An error occurred. Please try again.', 'error');
            }
        };


        const [tab, setTab] = useState('patients')

        return <div onClick={(e:MouseEvent<HTMLDivElement>)=>{
            let target = e.target as HTMLElement;
            if(!target.classList.contains('dropdown')){
                if(drop) setDrop('')
            }
        }} className='w-full h-full relative flex'>

            {
                params.scanId ? <ScanDetail/> : <>
                    <form onSubmit={(e)=>handlePatientCreation(e)} className="w-full flex sm:flex-row flex-col h-full lg:border lg:dark:border-gray-500 rounded-2xl lg:p-4">
                        <div className="min-h-[200px] sm:mb-0 mb-4 sm:h-full hidden sm:flex flex-col justify-between items-center sm:min-w-[300px] sm:max-w-[300px] rounded-2xl sm:mr-6 p-[1rem] sm:p-[3rem] sm:px-[2.5rem] overflow-y-auto dark:text-gray-100 dark:bg-darkBg bg-gradient-to-br dark:from-transparent dark:to-transparent from-[#4297c864] to-[#c4fff187]">
                            <div className="w-full flex flex-col">
                                <div className="sm:hidden w-full bg-gray-100 rounded-xl h-2 mb-[0.5rem]">
                                    <div style={{width: `${readValidation()}%`}} className="bg-sky-600 h-full rounded-xl"></div>
                                </div>

                                <section className="w-full flex sm:flex-col flex-row">
                                    <div className="flex flex-col sm:mr-0 mr-4">
                                        <div className="min-w-[100px] sm:w-full h-full sm:h-auto overflow-hidden rounded-xl scaleanimate">
                                            <img src={scanImage || skeleton} alt="" className='object-cover w-full h-full' />
                                        </div>
                                        <button className="border rounded-lg border-theme text-theme justify-center mt-2 px-4 sm:hidden flex items-center min-h-6 text-xs">
                                            Change
                                        </button>
                                    </div>
                                    <div className="w-full flex flex-col">
                                        <div className="sm:flex hidden w-full bg-gray-100 rounded-xl h-2 my-[1.6rem]">
                                            <div style={{width: `${readValidation()}%`}} className="bg-sky-600 h-full rounded-xl"></div>
                                        </div>

                                        <section className="w-full grid grid-cols-2 gap-4 mb-4">
                                            <div className="w-full flex flex-col">
                                                <small className='text-gray-500'>First name</small>
                                                <small>{details.first_name || '---'}</small>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <small className='text-gray-500'>Last name</small>
                                                <small>{details.last_name || '---'}</small>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <small className='text-gray-500'>Gender</small>
                                                <small>{details.gender || '---'}</small>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <small className='text-gray-500'>Date of Birth</small>
                                                <small>{details.dob || '---'}</small>
                                            </div>
                                        </section>
                                        <section className="w-full grid grid-cols-2 sm:grid-cols-1 gap-4 mb-4">
                                            <div className="w-full flex flex-col">
                                                <small className='text-gray-500'>Phone number</small>
                                                <small>{details.phone ? `${selectedCountry?.code||''} ${details.phone}` :  '---'}</small>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <small className='text-gray-500'>Email address</small>
                                                <small>{details.email || '---'}</small>
                                            </div>
                                        </section>
                                    </div>
                                </section>
                            </div>
                            <button onClick={()=>document.getElementById('scanImageFile')?.click()} className={`${scanImage?'flex':'hidden'} border rounded-3xl dark:border-gray-100 border-theme  dark:text-gray-100 text-theme text-sm px-8 items-center min-h-12`}>
                                Change Image
                            </button>
                        </div>
                        <div className="w-full h-full flex flex-col items-center sm:border dark:border-none border-theme p-4 sm:py-[3rem] overflow-y-auto dark:text-gray-100 dark:bg-darkBg bg-white rounded-2xl">
                            <h1 className="md:text-2xl font-semibold">About Patient</h1>
                            <small>Fill this form for complete information</small>
                            <div className="relative my-6 flex flex-col items-center">
                                <img src={scanImage || skeletonPlaceholder} alt="" className='w-[80px] sm:w-[130px] rounded-xl' />
                                <small className='dark:text-gray-100 text-gray-500'>{scanImage? 'Change Image':`Select scan image`}</small>
                                <input required id={'scanImageFile'} type="file" onChange={(e:any)=>{
                                    let file = e.target.files[0]
                                    setDetails({...details, file})
                                    setScanImage(URL.createObjectURL(file))
                                }} name="" className="w-full h-full top-0 left-0 absolute cursor-pointer opacity-0" />
                            </div>

                            <div className="w-full flex flex-col my-2">
                                <small>Prompt</small>
                                <textarea value={details.prompt} onChange={e=>setDetails({...details, prompt:e.target.value})} name="" placeholder='What do you want to know about the scan' id="" className='w-full p-4 py-2 mt-1 text-[0.8rem] max-h-[100px] border rounded-lg'></textarea>
                            </div>
                            <section className="w-full grid md:grid-cols-2 gap-4">
                                <div className="w-full flex flex-col my-2">
                                    <small>First name</small>
                                    <input required type="text" value={details.first_name} onChange={e=>setDetails({...details, first_name: e.target.value})} placeholder='Enter first name' className="border bg-transparent dark:border-gray-500 border-gray-300 rounded-xl px-3 text-sm w-full h-12 mt-1 outline-none" />
                                </div>
                                <div className="w-full flex flex-col my-2">
                                    <small>Last name</small>
                                    <input required type="text" value={details.last_name} onChange={e=>setDetails({...details, last_name: e.target.value})} placeholder='Enter last name' className="border bg-transparent dark:border-gray-500 border-gray-300 rounded-xl px-3 text-sm w-full h-12 mt-1 outline-none" />
                                </div>
                                <div className="w-full flex flex-col my-2">
                                    <small>Gender</small>
                                    <select required value={details.gender} onChange={e=>setDetails({...details, gender: e.target.value})} className="border bg-transparent dark:border-gray-500 border-gray-300 rounded-xl px-3 text-sm w-full h-12 mt-1">
                                        <option value="">Select gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                        {/* <option value="other">Other</option> */}
                                    </select>
                                </div>
                                <div className="w-full flex flex-col my-2">
                                    <small>Phone number</small>
                                    <div className='flex border bg-transparent dark:border-gray-500 border-gray-300 rounded-xl text-sm w-full h-12 mt-1'>
                                        <div onClick={()=>setDrop('dropCountries')} className={`min-w-14 h-full flex items-center ${selectedCountry?'justify-center':'justify-between'} px-1 group cursor-pointer border-r border-gray-300 pl-2 relative`}>
                                            <p className='text-lg'>{selectedCountry ? selectedCountry.flag : '🚩'}</p>
                                            <i className={`${selectedCountry&&'hidden'} fa ${drop?'fa-chevron-up':'fa-chevron-down'}`}></i>
                                            {
                                                drop && <section className="animatefromtop absolute top-12 w-18 max-h-[200px] rounded-xl left-0 border bg-gray-50 overflow-y-auto">
                                                    <div className="dropdown w-full h-10 border-b-2 bg-gray-50 sticky top-0">
                                                        <input required type="text" value={searchCountry} onChange={e=>setSearchCountry(e.target.value)} placeholder='Search' className="dropdown w-full h-full bg-transparent text-sm px-2 outline-none" />
                                                    </div>
                                                    {
                                                        displayCountries?.map((country:any, idx)=><div onClick={()=>{
                                                            setSelectedCountry({
                                                                flag: country.flag,
                                                                code: `${country?.idd?.root}${country?.idd['suffixes']?.slice(0,1)}`
                                                            });
                                                            // setSelectedCountry(`${country?.idd?.root}${country?.idd['suffixes']?.slice(0,1)}`);
                                                            
                                                            }} key={`country-${idx}`} className='w-full flex items-center justify-between cursor-pointer  hover:bg-gray-100 px-2'>
                                                            <div className='w-full flex items-center justify-between border-b py-3'>
                                                                <small>{country?.altSpellings[0]}</small>
                                                                <p className='mx-1'>{country?.flag}</p>
                                                                <small>{country?.idd?.root}{country?.idd['suffixes']?.slice(0,1)}</small>
                                                            </div>
                                                        </div>)
                                                    }
                                                </section>
                                            }
                                        </div>
                                        <input required type="text" value={details.phone} onChange={e=>setDetails({...details, phone: e.target.value})} placeholder='phone' className="bg-transparent w-full px-3 outline-none" />
                                    </div>
                                </div>
                            </section>
                            <div className="w-full flex flex-col my-2">
                                <small>Date of Birth</small>
                                <input required type="date" value={details.dob} onChange={e=>setDetails({...details, dob: e.target.value})} className="border bg-transparent dark:border-gray-500 border-gray-300 rounded-xl px-3 text-sm w-full h-12 mt-1 outline-none" />
                            </div>
                            <div className="w-full flex flex-col my-2">
                                <small>Email</small>
                                <input required type="email" value={details.email} onChange={e=>setDetails({...details, email: e.target.value})} placeholder='example@gmail.com' className="border bg-transparent dark:border-gray-500 border-gray-300 rounded-xl px-3 text-sm w-full h-12 mt-1 outline-none" />
                            </div>

                            <br />
                            <button className="border rounded-3xl border-theme text-theme text-sm px-12 flex items-center min-h-12">
                                Submit
                            </button>
                        </div>
                    </form>
                </>
            }

            <RightSideBar content={<>
                <div className="w-full flex flex-col items-center pt-4 dark:text-white">
                    <section className="w-full px-4 pt-4">
                        <div className="w-[240px] flex items-center p-1 border-2 border-gray-300 dark:border-gray-500 dark:text-white rounded-xl">
                            <button onClick={()=>setTab('history')} className={`w-full h-10 flex items-center justify-center rounded-xl mr-1 ${tab === 'history'?`bg-theme text-white`:`bg-transparent dark:text-white text-gray-700 hover:text-black bg-gray-100`}`}>
                                History
                            </button>
                            <button onClick={()=>setTab('patients')} className={`w-full h-10 flex items-center justify-center rounded-xl ${tab === 'patients'?`bg-theme text-white`:`bg-transparent dark:text-white text-gray-700 hover:text-black bg-gray-100`}`}>
                                Patients
                            </button>
                        </div>
                    </section>

                    {
                        tab === 'history' ? <>
                            <div className="my-4 mt-10 flex w-full items-center justify-between px-[1.7rem]">
                                <small>Scan History</small>
                                <div className="flex items-center justify-center w-6 h-6 rounded-lg dark:text-gray-100 text-black bg-gray-500 text-xs">{history?.length}</div>
                            </div>
        
                            {
                                (showHidden ? history : history.filter(his=>his?.description))?.map((his:any, idx:any)=>{
                                    return <div onClick={()=>{
                                        if(params.id !== his.id){
                                            navigate(`/smart-scan/${his.id}`)
                                        }
                                        setShow(false)
                                    }} key={idx} className={`flex flex-col w-full px-[1.7rem] py-4 cursor-pointer group hover:bg-[#4297c82c]`}>
                                        <aside className="flex items-center w-full mb-2">
                                            <div className="min-w-9 w-9 h-9 overflow-hidden mr-2">
                                                <img src={his?.image || skeleton} alt="" className="w-full h-full object-cover" />
                                            </div>
                                            <b><small>{his.image?.split('/')[5]?.slice(0,19)}</small></b>
                                        </aside>
                                        <div className="flex w-full mb-2">
                                            <b className='mr-2'>.</b>
                                            <small className='text-xs text-gray-500'>{his.description?.slice(0,60)}</small>
                                        </div>
                                        <div className="w-full flex items-center justify-between">
                                            <small className="text-[11px] text-gray-400">{his.created_at?.split('T')[0]}</small>
                                        </div>
                                    </div>
                                })
                            }
                            {history.some(his=>!his.description) && <b onClick={()=>setShowHidden(!showHidden)} className='text-sky-600 cursor-pointer hover:text-sky-500 text-sm'>{showHidden ? 'Show Less':'See Hidden'}</b>}
                            {
                                history?.length === 0 && <Empty comment="No Scan history yet"/>
                            }
                        </> : <>
                            <div className="my-4 mt-10 flex w-full items-center justify-between px-[1.7rem]">
                                <small>Patients</small>
                                <div className="flex items-center justify-center w-6 h-6 rounded-lg dark:text-gray-100 text-black bg-gray-500 text-xs">{patients?.length}</div>
                            </div>

                            {
                                (patients)?.map((patient:any, idx:any)=>{
                                    return <div onClick={()=>{
                                        setShow(false)
                                    }} key={idx} className={`flex w-full px-[1.7rem] py-4 cursor-pointer group hover:bg-[#4297c82c]`}>
                                        <b className='mr-2 mt-[0.06rem]'>{idx+1}</b>
                                        <section className='flex flex-col w-full'>
                                            <aside className="flex flex-col w-full">
                                                <p>{`${patient?.first_name} ${patient?.last_name}`}</p>
                                                <small className='text-theme'>{patient?.email}</small>
                                            </aside>
                                            <div className="w-full flex items-center justify-between py-2">
                                                <small className='text-xs text-gray-500'>{patient.gender}</small>
                                                <small className="text-[11px] text-gray-400">{convertDate(patient?.created_at)}</small>
                                            </div>
                                        </section>
                                    </div>
                                })
                            }
                        </>
                    }

                </div>
            </>} title='History'/>
        </div>
    }

    return <MainWrapper content={<ScanContent/>}  />
}

export default SmartScan