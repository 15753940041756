import React, { useEffect, useState } from 'react'
import MainWrapper from '../Components/MainWrapper'
import { peerEx, xray } from '../../../Assets/Images'
import RightSideBar from '../Components/RightSideBar'
import MessageInput from '../../../Common/MessageInput'
import { Link, useNavigate } from 'react-router-dom'
import Redesigned from './Redesigned'
import useMainStore from '../../../Store/Store'
import { scanServices } from '../../../Services/ScanServices'
import { peerExchangeServices } from '../../../Services/peerExchanges'
import Empty from '../../../Common/Empty'
import { upgradeServices } from '../../../Services/upgradeServices'
import { convertDate, convertTime } from '../../../Utils/convertTime'
import axiosInstance from '../../../Utils/axios'

function Dashboard() {
  const DashContent = () => {
    const navigate = useNavigate();
    const [mes,setMes] = useState('');
    const store = useMainStore()
    const {setShow} = store;
    const [data, setData] = useState({
      scan: [],
      groups: [],
      patients: [],
      peers: []
    })
    console.log(data)

    const fetchData =async () => {
      const his:any = await scanServices.getScans(store.user?.id);
      if(his){
        setData(prev=>{
          return {...prev, scan:his.results}
        })
      }

      const joined: any = await peerExchangeServices.getGroupChats('joined')
      if(joined){
        setData(prev=>{
          return {...prev, groups:his.results}
        })
      }

      const privateChats: any = await peerExchangeServices.getPrivateChats()
      if(privateChats){
        setData(prev=>{
          return {...prev, peers:his.results}
        })
      }

      axiosInstance.get(`/smartscans/users/patients`)
        .then(res=>{
          setData(prev=>{
            return {...prev, patients: res.data?.results}
          })
        }).catch(err=>{
            console.log(err)
        })

      upgradeServices.userPlan()
      .then(res=>{
          if(res.data && !res.data?.is_expired){
            store.setTransaction({
              ...res.data?.transaction,
              can_join_groups_no: res.data?.can_join_groups_no,
              id: res.data?.id,
              is_expired: res.data?.is_expired,
              learninghub_credits: res.data?.learninghub_credits,
              reloaded_at: res.data?.reloaded_at,
              smartscan_credits: res.data?.smartscan_credits,
            })

            store.setUser(res.data?.user)
          }else{
            store.setTransaction(null)
          }
      }).catch(()=>{})
    }

    useEffect(()=>{
      fetchData()
      // eslint-disable-next-line
    },[])

    return <div className='w-full flex h-full relative'>
      <section className="w-full flex flex-col overflow-hidden lg:px-0 sm:px-[8%] overflow-y-auto">
        {
          true ? <Redesigned data={data}/> : <>
            <div className="w-full md:min-h-[350px] flex flex-col-reverse md:grid grid-cols-2 gap-4 mb-6">
              
              <section onClick={()=>navigate(`/peer-exchange`)} className="rounded-2xl hover:opacity-50 cursor-pointer md:h-auto h-[200px] flex justify-center items-center flex-col dark:bg-darkBg bg-gradient-to-tr from-[#4297c8a1] to-[#c4fff194]">
                <img src={peerEx} alt="" />
                <br />
                <b className="text-skyBlue sm:text-xl">Peer Exchange</b>
              </section>

              <section className="rounded-2xl overflow-hidden md:h-auto h-[300px] relative">
                <img src={xray} alt="" className="object-cover h-full w-full" />
                <div className="absolute flex justify-center items-center flex-col top-0 w-full h-full left-0 bg-gradient-to-tr from-[#4297c88f] to-[#c4fff1a5]">
                  <b className="sm:text-xl text-deepTheme">Breact Cancer</b>
                  <br />
                  <div className="rounded-2xl p-4 lg:w-[300px] lg:min-h-[200px] border border-dashed border-gray-100 flex flex-col justify-center items-center">
                    <svg className='w-[35px] md:w-[50px]' viewBox="0 0 52 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.8274 18.1057C14.8859 18.1057 15.7449 18.9647 15.7449 20.0233C15.7449 21.0818 14.8859 21.9408 13.8274 21.9408H11.4419C7.30244 21.9408 3.93771 25.3056 3.93771 29.4424V41.9068C3.93771 46.0462 7.30244 49.4109 11.4419 49.4109H39.8989C44.0357 49.4109 47.403 46.0462 47.403 41.9068V29.4194C47.403 25.2953 44.0485 21.9408 39.927 21.9408H37.5159C36.4574 21.9408 35.5984 21.0818 35.5984 20.0233C35.5984 18.9647 36.4574 18.1057 37.5159 18.1057H39.927C46.163 18.1057 51.2382 23.1809 51.2382 29.4194V41.9068C51.2382 48.1607 46.1502 53.2461 39.8989 53.2461H11.4419C5.19054 53.2461 0.102539 48.1607 0.102539 41.9068V29.4424C0.102539 23.1911 5.19054 18.1057 11.4419 18.1057H13.8274ZM27.027 1.30147L34.4826 8.78773C35.2292 9.53942 35.2266 10.7513 34.4775 11.4979C33.7258 12.2445 32.5139 12.2445 31.7673 11.4928L27.5854 7.29648L27.5869 35.356H23.7518L23.7502 7.29648L19.574 11.4928C19.2007 11.8712 18.7072 12.0579 18.2163 12.0579C17.728 12.0579 17.2371 11.8712 16.8638 11.4979C16.1147 10.7513 16.1095 9.53942 16.8587 8.78773L24.3117 1.30147C25.0302 0.577897 26.3085 0.577897 27.027 1.30147Z" fill="#F7F4F1"/>
                    </svg>
                    <small className='text-center w-[200px] mt-4'>Drag as image here or click to upload a file</small>
                  </div>
                </div>
              </section>
            </div>

            <div className="w-full h-full flex flex-col items-center rounded-2xl p-6 bg-[#E0E6EB]">
              <section className="w-full h-full flex flex-col items-center justify-center">
                <b className="sm:text-xl text-deepTheme">What do you wish to learn today?</b>
                <br />
                  <div className="rounded-3xl bg-white p-1 flex w-[200px] overflow-hidden">
                    <input placeholder='Add Topic here' type="text" className="w-full h-full text-xs sm:text-sm border-none outline-none px-2" />
                    <aside className="min-w-[40px] h-[40px] rounded-full flex justify-center items-center bg-skyBlue">
                      <small className="fa fa-plus text-white"></small>
                    </aside>
                  </div>
                <br />
              </section>
              <MessageInput mes={mes} setMes={(val)=>setMes(val)} limitheight={200}/>
            </div>
          </>
        }
      </section>
      <RightSideBar className='animateshow3' title='Histories' content={<>
        <div className="w-full flex items-center justify-between px-6 pt-3">
          {/* <div className="w-10 h-10 bg-white flex items-center justify-center rounded-full overflow-hidden">
            {
              user?.avatar ? <img src={avatar} alt="" className='object-cover h-full w-full'/> :
              <small className="fa fa-user"></small>
            }
          </div> */}
          {/* <button className="px-8 py-2 rounded-2xl bg-theme text-white">Share</button> */}
        </div>
        {/* <br /> */}


        <div className="p-1 px-6 flex focus-within:flex-col focus-within:items-start items-center justify-between transition-all duration-500 dark:text-white">
          <b>Scan history</b>
          {/* <section className="focus-within:w-full h-10 group focus-within:mt-2 rounded-xl overflow-hidden flex items-center justify-center bg-[#6B7280] text-white">
            <input placeholder='Search here ...' type="text" className="w-full h-full pl-3 text-gray-50 outline-none bg-transparent border-none text-xs hidden group-focus-within:flex" />
            <button className="w-8 min-w-8 h-8 rounded-xl flex items-center justify-center bg-[#6B7280] text-white">
              <i className="fa fa-search text-xs"></i>
            </button>
          </section> */}
        </div>
        {
          (data.scan.slice(0,2))?.map((chat:any, idx)=>{
            return <div onClick={()=>{
              navigate(`/smart-scan/${chat?.id}`)
              setShow(false)
            }} key={`chat-${idx}`} className="w-full hover:bg-[#4297c81f] dark:text-white cursor-pointer p-5 px-6 flex flex-col">
              <h1 className="text-[14px] mb-2 font-semibold"><b className='mr-2'>{idx+1}</b>{chat?.description ? chat?.description?.slice(0,17) : 'N/A'}...</h1>
              <p className='text-[12px] mb-2 dark:text-gray-400'>{chat?.description?.slice(0,50)}... </p>
              <div className="w-full flex items-center justify-between">
                <small>{convertDate(chat?.created_at)}</small>
                <small>{convertTime(chat?.created_at)}</small>
              </div>
            </div>
          })
        }
        {data.scan?.length>2 && <Link to={`/smart-scan`} className='text-sky-600 cursor-pointer hover:font-extrabold px-6 font-bold text-sm'>See All</Link>}
        {data.scan?.length === 0 && <Empty/>}

        <br />
        <br />
        <br />
        <div className="p-1 px-6 flex focus-within:flex-col focus-within:items-start items-center justify-between transition-all duration-500 dark:text-white">
          <b>Chat history</b>
          {/* <section className="focus-within:w-full h-10 group focus-within:mt-2 rounded-xl overflow-hidden flex items-center justify-center bg-[#6B7280] text-white">
            <input placeholder='Search here ...' type="text" className="w-full h-full pl-3 text-gray-50 outline-none bg-transparent border-none text-xs hidden group-focus-within:flex" />
            <button className="w-8 min-w-8 h-8 rounded-xl flex items-center justify-center bg-[#6B7280] text-white">
              <i className="fa fa-search text-xs"></i>
            </button>
          </section> */}
        </div>
        {
          (data.groups?.slice(0,2))?.map((group:any, idx)=>{
            return <div onClick={()=>{
              navigate(`/peer-exchange/${group?.id}`)
              setShow(false)
            }} key={`peer-${idx}`} className="w-full hover:bg-[#4297c81f] dark:text-white cursor-pointer p-5 px-6 flex flex-col">
              <h1 className="text-[14px] mb-2 font-semibold"><b className='mr-2'>{idx+1}</b>{group?.description ? group?.description?.slice(0,17) : 'N/A'}...</h1>
              <p className='text-[12px] mb-2 dark:text-gray-400'>{group?.description?.slice(0,50)}... </p>
              <div className="w-full flex items-center justify-between">
                <small>{convertDate(group?.created_at)}</small>
                <small>{convertTime(group?.created_at)}</small>
              </div>
            </div>
          })
        }
        {data.groups?.length>2 && <Link to={`/peer-exchange`} className='text-sky-600 cursor-pointer hover:font-extrabold px-6 font-bold text-sm'>See All</Link>}
        {data.groups?.length === 0 && <Empty/>}
        </>}/>

    </div>
  }
  return <MainWrapper content={<DashContent/>}/>
}

export default Dashboard