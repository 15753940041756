import React, { useEffect, useState } from 'react'
import AuthWrapper from './Components/AuthWrapper'
import { authImage } from '../../Assets/Images'
import Input from '../../Common/Input'
import { fb, google } from '../../Assets/Icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useAuth from './Hooks/authHooks'
import { validatePassword } from '../../Utils/Validates'

function Signup() {
  const params = useParams()
  const navigate = useNavigate();
  const SignupContent = () => {
    const [details, setDetails] = useState({
      email: '',
      password: '',
      first_name: '',
      last_name:'',
      role: 'STUDENT',
      university: ''
    })
    const [hospitalCode, setHospitalCode] = useState('')
    const [type, setType] = useState('')
    const [check, setCheck] = useState(false)


    useEffect(()=>{
      setType(params.type || '');
      // eslint-disable-next-line
    }, [params]);

    const {universities, handleSubmit} = useAuth();


    return <div className={`w-full xs:w-[450px] sm:w-[500px] sm:p-6`}>
      <section className="w-full h-fit flex flex-col md:mb-[12rem]">
          {
            type ? <div onClick={()=>window.history.back()} className="my-4 cursor-pointer hover:text-gray-700 flex items-center">
                <i className="fa fa-arrow-left mr-2"></i>
              <p>Back</p>
            </div> : <></>
          }
          <h1 className="font-[600] text-xl sm:text-2xl mb-4 sm:mb-6">Sign up to eighty-nine</h1>

          <div className="w-full hidden justify-between h-[43px] md:h-[48px] text-white">
            <button className="w-full rounded-xl px-4 flex items-center bg-skyBlue ">
              <img src={google} alt="" />
              <div className="w-full text-center text-[14px] font-semibold">Sign in with Google</div>
            </button>
            <div className="min-w-3 md:min-w-6"></div>
            <button className="min-w-[43px] md:min-w-[48px] h-full border rounded-xl flex items-center justify-center">
              <img src={fb} alt="" />
            </button>
          </div>
          
          <div className="w-full hidden items-center text-gray-500 my-4">
            <hr className="text-gray-200 w-full" />
            <div className="text-sm mx-4">or</div>
            <hr className="text-gray-200 w-full" />
          </div>
          {
            !type && <div className="w-full flex flex-col">
              <button onClick={()=>navigate(`/auth/signup/student`)} className={`w-full flex items-center px-6 rounded-xl bg-[white] border-[1.5px] border-theme hover:bg-theme text-theme hover:text-white transition-all duration-200 ease-linear my-2 h-[110px] `}>
                {/* <img src={student} alt="" className="w-10" /> */}
                <i className="fa fa-graduation-cap text-2xl ml-4"></i>
                <div className="w-full text-center text-lg font-semibold">Sign up as a Student</div>
                
              </button>
              <button onClick={()=>navigate(`/auth/signup/partnership`)} className={`w-full flex items-center px-6 rounded-xl bg-[white] border-[1.5px] border-theme hover:bg-theme text-theme hover:text-white transition-all duration-200 ease-linear my-2 h-[110px] `}>
                {/* <img src={hospital} alt="" className="w-10" /> */}
                <i className="fa fa-hospital text-2xl ml-4"></i>
                <div className="w-full text-center text-lg font-semibold">Sign up as Partnership</div>
                
              </button>
            </div>
          }
          
          
          
          {
            type === 'student' ? 
            <form onSubmit={e=>handleSubmit(e, details, type, hospitalCode)} action="" className="w-full flex flex-col">
              <p className='my-2 mb-10 text-sky-600'>Signing up as a <b>Student</b></p>

              <Input value={details.email} onChange={(email:any)=>setDetails(prev=>{
                return {...prev, email}
              })} type='text' placeholder='Email address' className='mb-6 h-[48px]' />

              <section className="w-full flex mb-6">
                <Input value={details.first_name} onChange={(firstName:any)=>setDetails(prev=>{
                  return {...prev, first_name: firstName}
                })} type='text' placeholder='First name' className='h-[48px]' />
                <div className="mx-2"></div>
                <Input value={details.last_name} onChange={(lastName:any)=>setDetails(prev=>{
                  return {...prev, last_name: lastName}
                })} type='text' placeholder='Last name' className='h-[48px]' />
              </section>

              <select value={details.university} onChange={e=>setDetails({...details, university: e.target.value})} className='w-full h-[48px] mb-6 group relative border border-gray-500 rounded-xl px-6 flex items-center transition-all duration-700 ease-linear'>
                <option value="">Select school</option>
                { universities.length > 0 && 
                  universities?.map((uni:any)=><option key={uni?.id} value={uni?.key}>{uni?.name}</option>)
                }
              </select>

              <Input value={details.password} onChange={(password:any)=>setDetails(prev=>{
                return {...prev, password}
              })} type='password' placeholder='Password' className='h-[48px] mb-3' />
              {
                  (details.password.length > 4 && !validatePassword(details.password).isValid) && <>
                      {
                          validatePassword(details.password).errors.map((err, idx)=><small key={`err-${idx}`} className={`text-red-500`}>{err}</small>)
                      }
                  </>
              }

              <section className="w-full flex sm:flex-row flex-col sm:items-center justify-between my-6">
                <button className="rounded-xl bg-theme text-white w-full sm:mt-0 mt-4 h-[48px]">Sign up</button>
              </section>

              <section className="w-full flex">
                <div onClick={()=>setCheck(!check)} className={`min-w-6 h-6 rounded-md flex justify-center items-center cursor-pointer mr-2 ${check ? 'bg-green-600 text-white':'text-gray-500 border'}`}>
                  <i className="fa fa-check text-[14px]"></i>
                </div>
                <div className="w-full text-[12px]">
                  <p className='text-gray-600'>By clicking Create account, I agree that I have read and accepted the Terms of Use and Privacy Policy.</p>
                </div>
              </section>
            </form> : type === 'partnership' ? 
            <form onSubmit={e=>handleSubmit(e, details, type, hospitalCode)} action="" className="w-full flex flex-col">
              <p className='my-2 mb-10 text-sky-600'>Signing up as a <b>Partnership</b></p>

              <Input value={hospitalCode} onChange={(hospitalCode:any)=>setHospitalCode(hospitalCode)} type='text' placeholder='Hospital code' className='h-[48px] mb-6' inputClass='text-xl' />

              <select value={details.role} onChange={e=>setDetails({...details, role: e.target.value})} className='w-full h-[48px] mb-6 group relative border border-gray-500 rounded-xl px-6 flex items-center transition-all duration-700 ease-linear'>
                <option value="">Select role</option>
                <option value="DOCTOR">Doctor</option>
                <option value="REDIOLOGIST">Rediologist</option>
              </select>
              
              <hr className=' border-gray-300 w-full mb-6'/>
              
              <Input value={details.email} onChange={(email:any)=>setDetails(prev=>{
                return {...prev, email}
              })} type='text' placeholder='Email address' className='mb-6 h-[48px]' />

              <section className="w-full flex mb-6">
                <Input value={details.first_name} onChange={(firstName:any)=>setDetails(prev=>{
                  return {...prev, first_name: firstName}
                })} type='text' placeholder='First name' className='h-[48px]' />
                <div className="mx-2"></div>
                <Input value={details.last_name} onChange={(lastName:any)=>setDetails(prev=>{
                  return {...prev, last_name: lastName}
                })} type='text' placeholder='Last name' className='h-[48px]' />
              </section>

              <Input value={details.password} onChange={(password:any)=>setDetails(prev=>{
                return {...prev, password}
              })} type='password' placeholder='Password' className='h-[48px] mb-3' />
              {
                  (details.password.length > 4 && !validatePassword(details.password).isValid) && <>
                      {
                          validatePassword(details.password).errors.map((err, idx)=><small key={`err-${idx}`} className={`text-red-500`}>{err}</small>)
                      }
                  </>
              }


              <section className="w-full flex sm:flex-row flex-col sm:items-center justify-between my-6">
                <button className="rounded-xl bg-theme text-white w-full sm:mt-0 mt-4 h-[48px]">Sign up</button>
              </section>

              <section className="w-full flex">
                <div onClick={()=>setCheck(!check)} className={`min-w-6 h-6 rounded-md flex justify-center items-center cursor-pointer mr-2 ${check ? 'bg-green-600 text-white':'text-gray-500 border'}`}>
                  <i className="fa fa-check text-[14px]"></i>
                </div>
                <div className="w-full text-[12px]">
                  <p className='text-gray-600'>By clicking Create account, I agree that I have read and accepted the Terms of Use and Privacy Policy.</p>
                </div>
              </section>
            </form> : <></>
          }
          
      </section>

      <section className="text-[12px] sm:text-sm text-gray-500 md:mt-0 mt-[4rem]">
        <p>Protected by reCAPTCHA and subject to the <b className="text-theme font-normal">eighty-nine Privacy Policy</b> and <b className="text-theme font-normal">Terms of Service.</b></p>
      </section>
    </div>
  }


  return <AuthWrapper
    type={'register'}
    sideImage={authImage}
    subHead={<p className='flex sm:flex-row flex-col items-end'>New user? <Link to={'/auth/login'} className='text-theme hover:semibold text-[14px] ml-1'>Sign In</Link></p>}
    content={<SignupContent/>}
  />
}

export default Signup