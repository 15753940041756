import React, { ReactNode, useEffect } from 'react'
import useMainStore from '../Store/Store';
import { useLocation, useNavigate } from 'react-router-dom';


function Protected({page}:{page:ReactNode}) {
    const store = useMainStore();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        if(!store.user){
            navigate('/auth/login', {replace:true})
            localStorage.setItem('prev', location.pathname)
        }
        // eslint-disable-next-line
    },[store])

    return <>{page}</>
}

export default Protected