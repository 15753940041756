import React, { useEffect, useState } from 'react'
import MainWrapper from '../Components/MainWrapper'
import RightSideBar from '../Components/RightSideBar'
import { useNavigate, useParams } from 'react-router-dom'
import GroupChat from './GroupChat'
import usePeerExchange from './hooks/usePeerExchange'
import { imgFail, mediaUrl } from '../../../Services/mainServices'
import Modal from '../../../Common/Modal'
import CreateGroup from './CreateGroup'
import Empty from '../../../Common/Empty'
import useMain from '../../../Hooks/useMain'
import Peer from './Includes/PeerChat'
import useMainStore from '../../../Store/Store'
import { convertDate, convertTime } from '../../../Utils/convertTime'

function PeerExchange() {
    const params = useParams();
    const navigate = useNavigate();

    
    
    
    const PeerExchangeContent = () => {
        const {groupChats, setPrivateChats, joinedChats, setCreate, create, fetchData, handleJoinGroup, privateChats} = usePeerExchange();
        const {setShow} = useMainStore()
        const [search, setSearch] = useState('');
        const [display, setDisplay] = useState<any[]>([])
        const {setModal, modal, offModal} = useMain();



        useEffect(()=>{
            if(search){
                let res:any[] = joinedChats?.results?.filter((chat:any)=>chat.name?.toLowerCase().includes(search.toLowerCase()));
                setDisplay(res?.length>0?res:groupChats?.results)
            }else{
                setDisplay(joinedChats?.results)
            }
            // eslint-disable-next-line
        },[search, joinedChats])


        const [chatType, setChatType] = useState('groups')
        const [chatTitle, setChatTitle] = useState<any>(<></>)

        return <div className='w-full flex h-full lg:px-0 md:px-[5%]'>
            {
                create && <Modal close={()=>setCreate(false)} content={<CreateGroup fetchData={fetchData} close={()=>setCreate(false)}/>} title='Create Group' />
            }
            <section className="w-full lg:bg-[#E0E6EB] lg:dark:bg-[#222E44] dark:text-white rounded-lg lg:rounded-[32px] lg:p-4 flex flex-col items-center overflow-hidden overflow-y-auto">
                {
                    params.chatId ? <GroupChat/> : <>
                        <div className="w-full flex items-center justify-between py-4 md:pl-4">
                            <h1 className="md:block hidden text-[14px] lg:text-lg font-semibold dark:text-white text-deepTheme">Join a Chat!</h1>

                            <div className="sm:w-[330px] flex items-center rounded-md md:rounded-2xl bg-white px-3 h-[40px] overflow-hidden">
                                <i className="fa fa-search text-gray-500 mr-2"></i>
                                <input value={search} onChange={e=>setSearch(e.target.value)} type="text" placeholder='Search Chat' className="w-full h-full border-none outline-none bg-transparent text-gray-600 text-sm pl-1" />
                            </div>

                            <button onClick={()=>setCreate(true)} className="px-4 flex items-center h-[40px] text-sm sm:text-[13px] lg:text-[15px] rounded-md md:rounded-2xl bg-theme text-white">
                                <i className="fa fa-plus mr-3"></i>
                                <p className='font-semibold'>Create Chat</p>
                            </button>
                        </div>

                        
                        <div className="w-full rounded-[2rem] p-[1.5rem] md:p-[2.5rem] dark:bg-darkBg bg-[#EBF0F5] mb-4">
                            <section className="w-full flex justify-between mb-5">
                                <div className="flex flex-col">
                                    <h1 className="text-lg text-skyBlue font-semibold">Chats you're part of</h1>
                                    <p className='text-gray-600 text-sm'>Conversations you might be interested in.</p>
                                </div>
                                {/* <b className="text-skyBlue hover:text-sky-800 cursor-pointer">SEE ALL</b> */}
                            </section>
                            
                            {
                                joinedChats?.results?.length > 0 ? <section className="w-full grid sm:grid-cols-2 2xl:grid-cols-3 xxl:grid-cols-4 gap-6">
                                    {
                                        display?.map((chat:any, idx:any)=>{
                                            return <div key={`joined-${idx}`} onClick={()=>navigate(`/peer-exchange/group/${chat.id}`, {state: {chat}})} className="sm:mx-0 mx-auto w-full bg-gray-50 cursor-pointer hover:scale-105 transition-all duration-300 hover:bg-hoverFade flex flex-col justify-between rounded-lg md:rounded-3xl shadow-sm overflow-hidden">
                                                <aside className="w-full">
                                                    <div className={`w-full flex items-center justify-center h-[200px] xl:h-[230px] border ${!chat?.profile && 'border-gray-300'} overflow-hidden rounded-t-lg md:rounded-t-3xl`}>
                                                        {
                                                            chat?.profile ? <img src={mediaUrl + chat?.profile} alt="" className='w-full h-full object-cover' /> :
                                                            <img src={'https://nbhc.ca/sites/default/files/styles/article/public/default_images/news-default-image%402x_0.png?itok=B4jML1jF'} alt="" className='w-full h-full object-cover' />
                                                        }
                                                    </div>
                                                    <div className="w-full p-4 py-6 flex flex-col">
                                                        <p className="text-theme font-semibold">{chat.name}</p>
                                                        <div className="w-full flex items-end text-gray-600">
                                                            <small>{chat.creator?.first_name || ''}</small>
                                                            <i className="fa fa-circle mx-2 text-[0.3rem] mb-1"></i>
                                                            {/* <small>{chat.members || 20} Members</small> */}
                                                        </div>
                                                    </div>
                                                </aside>
                                            </div>
                                        })
                                    }
                                </section> : <Empty comment="You're currently not a part or any group"/>
                            }
                        </div>

                        {
                            display?.length<4 && <>
                            <div className="w-full flex flex-col rounded-[2rem] p-[1.5rem] md:p-[2.5rem] dark:bg-darkBg bg-[#EBF0F5]">
                            <section className="w-full flex justify-between mb-2">
                                <div className="flex flex-col">
                                    <h1 className="text-lg text-skyBlue font-semibold">Suggested Chats</h1>
                                    <p className='text-gray-600 text-sm'>Conversations you might be interested in.</p>
                                </div>
                                {/* <b className="text-skyBlue hover:text-sky-800 cursor-pointer">SEE ALL</b> */}
                            </section>

                            {
                                groupChats?.results?.length>0 ? <section className="w-full overflow- border-r-4 border-gray-500">
                                    <aside className="w-full overflow-hidden overflow-x-auto">
                                        <div className="flex w-fit py-4 pl-4">
                                            {
                                                groupChats?.results?.map((chat:any, idx:any)=>{
                                                    return <div key={`group-${idx}`} className="mr-3 w-[270px] cursor-pointer hover:scale-105 transition-all bg-gray-50 pb-4 hover:bg-hoverFade flex flex-col justify-between rounded-lg md:rounded-3xl overflow-hidden">
                                                        <aside className="w-full">
                                                            <div className={`w-full flex items-center  md:h-[150px] xl:h-[200px] justify-center border ${!chat?.profile && 'border-gray-300'} overflow-hidden rounded-t-lg md:rounded-t-3xl`}>
                                                                {
                                                                    chat?.profile ? <img src={mediaUrl + chat?.profile} alt="" className='w-full h-full object-cover' /> :
                                                                    <img src={'https://nbhc.ca/sites/default/files/styles/article/public/default_images/news-default-image%402x_0.png?itok=B4jML1jF'} alt="" className='w-full h-full object-cover' />
                                                                }
                                                            </div>
                                                            <div className="w-full p-4 flex flex-col">
                                                                <p className="text-theme font-semibold">{chat.name}</p>
                                                                <div className="w-full flex items-end text-gray-600">
                                                                    <small>{chat.creator?.first_name || ''}</small>
                                                                    <i className="fa fa-circle mx-2 text-[0.3rem] mb-1"></i>
                                                                    {/* <small>{chat.members || 20} Members</small> */}
                                                                </div>
                                                            </div>
                                                        </aside>
                                                        <div className="w-full px-4">
                                                            <button className="mt-4 w-full font-semibold rounded-2xl bg-skyBlue text-white h-[40px] flex justify-center items-center">
                                                                Join Chat
                                                            </button>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </aside>
                                </section> : <Empty comment={<>No groups available to join <br/> Be the first to create</>}/>
                            }
                            
                        </div>

                        <br />
                            </>
                        }
                    </>
                }
            </section>

            {/* Right Sidebar ..... */}
            <RightSideBar title='View peers' content={<>
                <section className="w-full px-4 pt-4">
                    <div className="w-[240px] flex items-center p-1 border-2 border-gray-300 dark:border-gray-500 dark:text-white rounded-xl">
                        <button onClick={()=>setChatType('groups')} className={`w-full h-10 flex items-center justify-center rounded-xl mr-1 ${chatType === 'groups'?`bg-theme text-white`:`bg-transparent dark:text-white text-gray-700 hover:text-black bg-gray-100`}`}>
                            Groups
                        </button>
                        <button onClick={()=>setChatType('peers')} className={`w-full h-10 flex items-center justify-center rounded-xl ${chatType === 'peers'?`bg-theme text-white`:`bg-transparent dark:text-white text-gray-700 hover:text-black bg-gray-100`}`}>
                            Peers
                        </button>
                    </div>
                </section>

                <div className="w-full flex items-center justify-between px-4 pt-6">
                    <p className='text-gray-500 font-[500]'>{chatType === 'groups'?'Other Groups':'Peers'}</p>
                </div>

                {
                    (chatType === 'groups' ? groupChats:privateChats)?.results?.length>0 ? <>
                        {
                            chatType === 'groups' ? 
                            groupChats?.results?.map((chat:any, idx:number)=>{
                                return <div key={`peers-${idx}`}>
                                        <div key={`peers-${idx}`} className="p-3 px-4 flex items-center justify-between">
                                            <aside className="flex items-center">
                                                <div className="min-w-[38px] w-[38px] h-[38px] rounded-lg border-2 border-theme overflow-hidden mr-2">
                                                    <img src={chat.profile ? `${mediaUrl + chat.profile}`:imgFail} alt="" className='w-full h-full object-cover'/>
                                                </div>
                                                <div className="flex flex-col">
                                                    <p className="font-500 font-semibold text-[0.8rem] dark:text-white text-deepTheme">{chat.name?.slice(0,14)}</p>
                                                    <p className='text-[0.75rem] dark:text-gray-300'>{chat.created_at?.split('T')[0]}</p>
                                                </div>
                                            </aside>
                                            <aside onClick={()=>{
                                                handleJoinGroup(chat);
                                                setShow(false)
                                            }} className="bg-sky-600 rounded-md px-5 flex items-center h-7 text-[0.7rem] text-white text-sm cursor-pointer hover:bg-sky-900">
                                                Join
                                            </aside>
                                        </div>
                                    </div>
                            }) : privateChats?.results?.map((peer:any, idx:number)=>{
                                return <div onClick={()=>{
                                    setChatTitle(<div className='flex items-center '>
                                        <div className="min-w-10 w-10 h-10 rounded-full flex items-center justify-center bg-gray-200 mr-2">
                                            {
                                                peer?.avatar ? <img src={`${mediaUrl + peer?.avatar}`} alt="" className='w-full h-full object-cover'/> :
                                                <i className="fa fa-user"></i>
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <div className=' text-[12px]'>{`${peer?.first_name} ${peer.last_name}`?.slice(0,30)}</div>
                                            {/* <small className='h-5 text-[12px]'>July, 2024</small> */}
                                        </div>
                                    </div>);
                                    setModal(<Peer peer={peer.id}/>);
                                    setShow(false);
                                    setPrivateChats((prev:any)=>{
                                        let selectedPeer = peer;
                                        delete selectedPeer?.unread_chats;
                                        const list = [...prev?.results];
                                        list.splice(idx,1,selectedPeer)

                                        return {...prev, results: list}
                                    })
                                }} key={`peers-${idx}`}>
                                        <div key={`peers-${idx}`} className="p-3 px-4 flex items-center justify-between hover:bg-gray-100 cursor-pointer">
                                            <aside className="flex items-center">
                                                <div className="min-w-[38px] w-[38px] h-[38px] rounded-full border-2 border-theme overflow-hidden mr-2 flex justify-center items-center bg-sky-50">
                                                    {
                                                        peer?.avatar ? <img src={`${mediaUrl + peer?.avatar}`} alt="" className='w-full h-full object-cover'/> :
                                                        <small className="fa fa-user dark:text-theme"></small>
                                                    }
                                                </div>
                                                <div className="flex flex-col">
                                                    <p className="font-500 font-semibold text-[0.8rem] dark:text-white text-deepTheme">{`${peer?.first_name} ${peer.last_name}`?.slice(0,20)}</p>
                                                    <p className='text-[0.7rem] dark:text-gray-300'>{convertDate(peer.last_login)} | {convertTime(peer.last_login)}</p>
                                                </div>
                                            </aside>
                                            <b className="text-emerald-600 text-sm">{peer?.unread_chats}</b>
                                        </div>
                                    </div>
                            })
                        }
                    </> : <Empty/>
                }
                
            </>}/>

            {modal && <Modal content={modal} close={()=>{
                offModal()
            }} title={chatTitle}/>}
        </div>
    }
  return <MainWrapper content={<PeerExchangeContent/>}/>
}

export default PeerExchange