import { useEffect, useRef, useState } from 'react'
import { peerExchangeServices } from '../../../../Services/peerExchanges'
import { useParams } from 'react-router-dom'
import useMainStore from '../../../../Store/Store';
import { chatUrl } from '../../../../Services/mainServices';


function useGroupMessage(group:string|any , token:string|any) {
    const user = useMainStore().user
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const postBtn = useRef<HTMLButtonElement>(null)
    const [drop, setDrop] = useState<any>(null)
    const params = useParams();
    const [groupMessage, setGroupMessage] = useState<any[]>([])

    const fetchData =async ()=>{
        console.log(params.chatId)
        peerExchangeServices.getGroupMessage(params.chatId || '')
        .then(res=>{
            console.log(res)
            setGroupMessage(()=>{
                return res.data
            })
        }).catch(err=>console.log(err))
        
    }

    useEffect(()=>{
        fetchData();
        window.addEventListener('click', (event: MouseEvent) => {
            const target = event.target as Element;
            if (!target.classList.contains('drop')) {
                setDrop(null);
            }
        });
        // eslint-disable-next-line
    },[]);

    function convertToJson(data:any){
			let jsonString:any = data.replace(/'/g, '"').replace(/None/g, "null").replace(/\bTrue\b/g, "true").replace(/\bFalse\b/g, "false");
			return JSON.parse(jsonString)
		}
    
    useEffect(()=>{
        let url = `${chatUrl}/ws/group/${params.chatId}?token=${token}`
        const socket = new WebSocket(url);
        const connectSocker = () => {
            socket.addEventListener('open', (event) => {
                console.log('Connected to server.')
            });
            socket.addEventListener('close', (event) => {
                console.log('Connection closed.');
                socket.close();
            });
            
            socket.onmessage = (event) => {
                try{
                    
                    if(event.data?.includes('Client #')){
                        console.log('onconnect',event.data)
                    }else{
                        let message:any = convertToJson(event.data)
                        console.log(message.created_data)

                        const data = {
                            comments: [],
                            comments_count: 0,
                            likes_count: 0,
                            ilike: false,
                            message: {
                                content: message.created_data?.content,
                                group: message.created_data?.group,
                                id: message.created_data?.id,
                                image: message.created_data?.image,
                                created_at: message.created_data?.created_at,
                                updated_at: message.created_data?.updated_at,
                                user: message.created_data?.user
                            }
                        };

                        setGroupMessage((prev:any)=>{
                            return [data, ...prev]
                        })
                        if(inputRef.current){
                            inputRef.current.value = '';
                        }
                    }

                }catch(err){
                    console.log(event.data);
                    return
                }
            }
        };
        connectSocker();
        
        const sendMessage = (inputValue: any) => {
            let data = {
                content: inputValue,
                user
            }
            socket.send(JSON.stringify(data))
        }
        
        if(postBtn.current){
            postBtn.current.addEventListener('click', (e)=>{
                if(inputRef.current && inputRef.current.value){
                    sendMessage(inputRef.current.value)
                }
                
            })
        }

        return ()=>{
            socket.close();
        }
        // eslint-disable-next-line
    }, [inputRef])


    const handleDeletePost =async (post:any, idx:number) => {
        const filterout = groupMessage?.filter((val:any)=>val?.id !== post?.id)
        setGroupMessage(()=>{
            return filterout
        })
        const res = await peerExchangeServices.deletePost(post?.id);
        if(res){
            fetchData();
        }
    }
    
    
    
    return {
        groupMessage, handleDeletePost,
        fetchData,
        inputRef,
        postBtn,
        drop,
        setDrop,
        setGroupMessage
        // sendMessage,
    }
}

export default useGroupMessage