import React, { ReactNode, useEffect, useRef, useState } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import getDevice from '../../../Utils/Device'
import ScrollToTop from '../../../Common/ScrollToTop'

type props = {
    content: ReactNode,
    nonAuth?: boolean
}

function MainWrapper({content, nonAuth}:props) {
  const [showSidebar, setShow] = useState(false)
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(()=>{
    setInnerWidth(window.innerWidth)
    window.addEventListener('resize',()=>{
      setInnerWidth(window.innerWidth)
      if(window.innerWidth < 1024){
        setShow(false)
      }
    })
  },[])

  const mainContainerRef = useRef<any>(null)
  useEffect(() => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <div className={`w-screen h-[100dvh] p-4 ${getDevice() === 'Mobile' && 'pb-0 px-2'}  pt-0 flex dark:bg-[#1A1D2D] bg-[#F7F4F1] overflow-hidden`}>
        {!nonAuth && <Sidebar close={()=>setShow(false)} show={showSidebar}/>}
        <section className={`${innerWidth < 1024 ? 'w-full':`${!nonAuth ? 'w-[calc(100%-264px)]':'w-full'}`} h-full z-10`}>
          {!nonAuth && <Header showSideBar={()=>setShow(true)}/>}
            <div ref={mainContainerRef} className={`w-full ${nonAuth ? 'h-full':'h-[calc(100%-90px)]'} relative flex flex-col items-center`}>
                <ScrollToTop/>
                {content}
            </div>
        </section>
    </div>
  )
}

export default MainWrapper