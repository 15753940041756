import React, { ReactNode, useEffect, useState } from 'react'
import useMainStore from '../../../Store/Store';

type props = {
    content: ReactNode,
    title: string,
    className?: string
}

function RightSideBar({content, title, className}:props) {
  const {show, setShow} = useMainStore()
  const [innerWidth, setInnerWidth] = useState(0)

  useEffect(()=>{
    setInnerWidth(window.innerWidth)
    window.addEventListener('resize', ()=>{
      setInnerWidth(window.innerWidth)
      if(show){
        setShow(false)
      }
    })
    // eslint-disable-next-line
  },[])

  if(show && (innerWidth < 1280)){
    return <section className={`fixed right-0 top-0 xl:hidden h-full w-full flex justify-end`}>
      <div onClick={()=>setShow(false)} className="absolute bg-[#00000047] top-0 left-0 h-full w-full"></div>
      <div className={`${show ? `${innerWidth < 600 ? 'animatefrombottom min-w-[70%] w-[70%':'animatefromright min-w-[272px] w-[272px]'}` : 'transformRight'} min-w-[272px] w-[272px] shadow-md border-l dark:bg-[#222E44] bg-[#F1EFEF] rounded- pb-5 relative h-full overflow-y-auto ` }>
        {content}
      </div>
    </section>
  }
  return (
    <div onClick={e=>e.stopPropagation()} className={`xl:min-w-[272px] xl:w-[272px] dark:text-gray-200  xl:h-full h-fit ml-[2rem] flex flex-col overflow-y-auto absolute bottom-0 right-0 xl:relative ${show ? 'right-1 xl:right-0':'right-0'} ${className}`}>
        {/* {
          !show && <div onClick={(e)=>{
            e.stopPropagation();
            setShow(true)
          }} className="sticky top-0 left-0 flex justify-end xl:hidden">
            <aside className='rounded-2xl w-fit px-4 h-10 mb-2 ml-2 bg-skyBlue shadow-xl text-white mt-3 cursor-pointer flex items-center justify-center'>
              <small className="fa fa-chevron-left mr-3"></small>
              <small>{title}</small>
            </aside>
          </div>
        } */}
        <div onClick={()=>setShow(false)} className="w-full h-fit hidden xl:block dark:bg-darkBg bg-[#F1EFEF] rounded-xl pb-5">
          {content}
        </div>
    </div>
  )
}

export default RightSideBar